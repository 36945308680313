export default {
    /*网站设置*/
    title:'AI爱学习导航 - 助你畅游AI',  //网站标题
    logoText:'',    //网站文字logo,不填则不显示
    navStyle:2,      //网址导航样式：1经典样式，2卡片样式
    connect:{       //右上角小铺按钮
        text:'小铺',
        href:'http://kh.aiaxx.cn'
    },

    /*以下为开启或关闭组件，0代表关闭，1代表开启*/
    showBanner:1,   //轮播banner
    showAddWeb:1,   //自定义添加网址
    showTip:0,      //鼠标悬浮提示
    showLogo:1,     //顶部的网站logo图片
}
